import styled from "styled-components";



export const Container1 = styled.div`
  height: 190px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
`;
export const Holder1 = styled.div`
   height: 60px;
   margin: 4px 0px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
`;

export const Circle1 = styled.div`
  height:50px;
  width:50px;
  border-radius: 60%;
  background-color: white;
  position: absolute;
  z-index: 4;
    left: 10%;
  display: flex;
   justify-content: center;
   align-items: center;
`;
export const Circle2 = styled.div`
   height: 45px;
   width: 45px;
   /* border: 2.5px solid  #F26724; */
   border-radius: 45px;
   background-color: #F26724;
   display: flex;
   justify-content: center;
   align-items: center;
`;
export const Circle3 = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: white;
`;
export const Profileimg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 100%;
`;
export const Title1 = styled.div`
  height: 60%;
  width: 60%;
  background-color: white;
  position: absolute;
  z-index: 2;
  right: 10%;
  border-top-right-radius:10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
`;


export const Name1 = styled.div`
  width: 80%;
  overflow: hidden;
  height: 70%;
  font-size: 100%;
  text-align: center;
`;

export const Spend1 = styled.div`
 width: 80%;
  overflow: hidden;
  height: 50%;
   font-size: 80%;
   color: #F26724;
   text-align: center;
`;

export const MiddleLineDiv = styled.div`
    height: 80%;
    position: absolute;
    z-index: 1;
    left: 25%;
    top: 10%;
`;
export const ShoppingLineImage=styled.img`
top: 30%;
position: absolute;
  width: 60px;
`;
export const ShoppingLine = styled.div`
  height: 100%;
  width: 6px;
  display: flex;
  justify-content: center;
  background-color: gray;
`;
export const InsideShoppingLine=styled.div`
 height: 40%;
  width: 5px;
  background-color: #F26724;
  border-radius: 8px;
`;