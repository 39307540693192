

import React, { useEffect } from 'react'
import { FriendCircleBox1, FriendCircleBox2, FriendCircleBox3, FriendCircleContainer } from './FriendCircle.styled'
import ProfileComponent from './ProfileComponent'
import OwnProfile from './OwnProfile'
import useFooddyStore from '../../../store'

import man1Icon from "../../../assets/menEmoji/man1.png";
import man2Icon from "../../../assets/menEmoji/man2.png";
import man3Icon from "../../../assets/menEmoji/man3.png";
import man4Icon from "../../../assets/menEmoji/man4.png";
import man5Icon from "../../../assets/menEmoji/man5.png";
import man6Icon from "../../../assets/menEmoji/man6.png";
import man7Icon from "../../../assets/menEmoji/man7.png";
import man8Icon from "../../../assets/menEmoji/man8.png";

// import im from '../../assets/menEmoji/man1.png'

import woman1Icon from "../../../assets/womenEmoji/woman1.png";
import woman2Icon from "../../../assets/womenEmoji/woman2.png";
import woman3Icon from "../../../assets/womenEmoji/woman3.png";
import woman4Icon from "../../../assets/womenEmoji/woman4.png";
import woman5Icon from "../../../assets/womenEmoji/woman5.png";
import woman6Icon from "../../../assets/womenEmoji/woman6.png";
import woman7Icon from "../../../assets/womenEmoji/woman7.png";
import woman8Icon from "../../../assets/womenEmoji/woman8.png";
import woman9Icon from "../../../assets/womenEmoji/woman9.png";
import woman10Icon from "../../../assets/womenEmoji/woman10.png";
import woman11Icon from "../../../assets/womenEmoji/woman11.png";
import woman12Icon from "../../../assets/womenEmoji/woman12.png";
let styleList = [
    {
        height: "35px",
        width: "35px",
        transform: "translateY(-60px) translateX(0px)"
    },
    {
        height: "20px",
        width: "20px",
        transform: "translateY(45px) translateX(-100px)"
    },
    {
        height: "30px",
        width: "30px",
        transform: "translateY(75px) translateX(50px)"
    },
    {
        height: "20px",
        width: "20px",
        transform: "translateY(-25px) translateX(110px)"
    },

    {
        height: "35px",
        width: "35px",
        transform: "translateY(40px) translateX(-40px)"
    },
    {
        height: "20px",
        width: "20px",
        transform: "translateY(-110px) translateX(20px)"
    },
    {
        height: "30px",
        width: "30px",
        transform: "translateY(20px) translateX(90px)"
    },
    {
        height: "20px",
        width: "20px",
        transform: "translateY(-80px) translateX(-75px)"
    },
    {
        height: "20px",
        width: "20px",
        transform: "translateY(90px) translateX(-65px)"
    },
    {
        height: "30px",
        width: "30px",
        transform: "translateY(0px) translateX(-90px)"
    },

    {
        height: "20px",
        width: "20px",
        transform: "translateY(110px) translateX(20px)"
    },

    {
        height: "20px",
        width: "20px",
        transform: "translateY(-65px) translateX(65px)"
    }
]
let menImages = [
    man1Icon,
    man2Icon,
    man3Icon,
    man4Icon,
    man5Icon,
    man6Icon,
    man7Icon,
    man8Icon
]
let womenImages=[
    woman1Icon,
    woman2Icon,
    woman3Icon,
    woman4Icon,
    woman5Icon,
    woman6Icon,
    woman7Icon,
    woman8Icon,
    woman9Icon,
    woman10Icon,
    woman11Icon,
    woman12Icon,
]
function FriendCircle() {
    const { friends,setActiveFriend} = useFooddyStore()
    useEffect(()=>{
        if(friends.length){
            let friend=friends[0]
            if(friend.gender==='male') friend['friendDp']=menImages[0]
            else friend['friendDp']=womenImages[0]
            setActiveFriend(friend)
        }
        
    },[friends])
    return (
        <>
            <FriendCircleContainer>
                <FriendCircleBox1>
                    <FriendCircleBox2>
                        <FriendCircleBox3>
                        </FriendCircleBox3>
                    </FriendCircleBox2>
                </FriendCircleBox1>
                <OwnProfile />
                {
                    friends.map((e, i) => {
                        if (i < styleList.length) return e.gender==='male' ? (<ProfileComponent style={styleList[i]} key={i} friendDp={menImages[i]} friend={friends[i]}/>) : (<ProfileComponent style={styleList[i]} key={i} friendDp={womenImages[i]} friend={friends[i]}/>)
                    })
                }
            </FriendCircleContainer>
        </>
    )
}

export default FriendCircle
