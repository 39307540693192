import React from 'react'
import { CardContainer, ImageContainer, MainContainer, OrangeBackground, UserAmount, UserInfo, UserName,ProfileImage } from './OwnProfile.styled'


import profile from '../../../pictures/profile.png'
import useFooddyStore from '../../../store'

function OwnProfile() {
  const {
    userPrflPic,
    userName,
    totalAmount
} = useFooddyStore()
  return (
    <MainContainer>
      <CardContainer>
        <ImageContainer>
          <OrangeBackground>
          </OrangeBackground>
        </ImageContainer>
        <ProfileImage src={profile} alt="Profile" />
      </CardContainer>
      <UserInfo>
          <UserName>{userName}</UserName>
          {totalAmount ? <UserAmount>₹{totalAmount}</UserAmount> : <UserAmount>----</UserAmount>}
      </UserInfo>
    </MainContainer>
  )
}

export default OwnProfile
