import {
  ShareContainer,
  ShareTitle,
  ButtonsContainer,
  Button,
} from "./Share.styled";
import Strings from "../../constants/Strings";
import useFooddyStore from "../../store";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
function SharePopup() {
  const { isShareClicked, isZomatoActivated, fooddyId, totalAmount } =
    useFooddyStore();
  const currentUrl = window.location.href;
  if (!isShareClicked) return;
  return (
    <ShareContainer>
      <ShareTitle>{Strings.shareTitle}</ShareTitle>
      <ButtonsContainer>
        <Button>
          <FacebookShareButton
            url={currentUrl}
            quote={`Just tallied up my ${isZomatoActivated ? "zomato" : "swiggy"
              } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! 
My Ecom report: https://app.ecombullet.com/id/${isZomatoActivated ? "zomato" : "swiggy"
              }/${fooddyId}`}
            hashtag="#Ecom"
          >
            <FacebookIcon size={20} round />
          </FacebookShareButton>
        </Button>
        <Button>
          <TwitterShareButton
            url={currentUrl}
            title={`Just tallied up my ${isZomatoActivated ? "zomato" : "swiggy"
              } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! 
My Ecom report: `}
            hashtags={["fooddy", "swiggy", "zomato", "spending_calculator"]}
          >
            <TwitterIcon size={20} round />
          </TwitterShareButton>
        </Button>
        <Button>
          <WhatsappShareButton
            url={currentUrl}
            title={`Just tallied up my ${isZomatoActivated ? "zomato" : "swiggy"
              } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! 
My Ecom report: https://app.ecombullet.com/id/${isZomatoActivated ? "zomato" : "swiggy"
              }/${fooddyId}`}
          >
            <WhatsappIcon size={20} round />
          </WhatsappShareButton>
        </Button>
        <Button>
          <LinkedinShareButton
            url={currentUrl}
            title={`Just tallied up my ${isZomatoActivated ? "zomato" : "swiggy"
              } online ecom expenses`}
            summary={`A whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! `}
            source={`https://app.ecombullet.com/id/${isZomatoActivated ? "zomato" : "swiggy"
              }/${fooddyId}`}
          >
            <LinkedinIcon size={20} round />
          </LinkedinShareButton>
        </Button>
      </ButtonsContainer>
    </ShareContainer>
  );
}

export default SharePopup;
