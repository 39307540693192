import React from "react";
import Header from "../Header/Header";
import { Expired } from "./Main2.styled";
function Main2() {
  return (
    <div>
      <Header />
      <Expired>
        The link has been expired. please calculate your spending again using
        &nbsp;
        <a href="https://chrome.google.com/webstore/detail/spending-tracker-for-amaz/pmkoilojjepdgnbhnbfmbpjbcgfgbaoe">
          {" "}
          Extension
        </a>
        !!
      </Expired>
    </div>
  );
}

export default Main2;
