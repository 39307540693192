import React, { useEffect, useState } from 'react'
import { ChartHeadingDiv, ChartSectionDiv, DataChangeDiv, DateChangeLogo, DateChangeLogoHolder, DateSection, DateText, DateTopSection, MutableSection, Option, PlatormName, RightMutableSection } from './ChartSection.styled'
import vector from '../../pictures/Vector.png'
import Chart from './Chart'
import useFooddyStore from '../../store'
import { useParams } from 'react-router-dom'
import axios from 'axios'
function ChartSection() {
  const [toggle, setToggle] = useState(false)
  const params = useParams();
  const {
    userId,
    swiggyActive,
    yearlyFlipkartGraphData,
    setYearlyFlipkartGraphData,
    monthlyFlipkartGraphData,
    setMonthlyFlipkartGraphData,
    weeklyFlipkartGraphData,
    setWeeklyFlipkartGraphData,
    yearlyAmazonGraphData,
    setYearlyAmazonGraphData,
    monthlyAmazonGraphData,
    setMonthlyAmazonGraphData,
    weeklyAmazonGraphData,
    setWeeklyAmazonGraphData,
    graphState,
    setGraphState
  } = useFooddyStore()

  const fetchYearlyFlipkartGraphData = async () => {
    const currentYear = new Date().getFullYear();
    let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/flipkart/yearlyproductdetails`,
      {
        userId: params.userid,
        year: currentYear,
      })

    if (response.data) {
      if (response.data.message !== 'No user Found') setYearlyFlipkartGraphData(response.data)
    }
  }
  const fetchYearlyAmazonGraphData = async () => {
    const currentYear = new Date().getFullYear();
    let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/amazon/yearlyproductdetails`,
      {
        userId: params.userid,
        year: currentYear,
      })

    if (response.data) {
      if (response.data.message !== 'No user Found') setYearlyAmazonGraphData(response.data)
    }
  }
  const fetchMonthlyFlipkartGraphData = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0 for January, 1 for February, etc.

    const yearMonth = `${year}-${month < 10 ? '0' : ''}${month}`;
    let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/flipkart/monthlyproductdetails`,
      {
        userId: params.userid,
        month: yearMonth,
      })

    if (response.data) {
      if (response.data.message !== 'No user Found') setMonthlyFlipkartGraphData(response.data)
    }
  }
  const fetchMonthlyAmazonGraphData = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0 for January, 1 for February, etc.

    const yearMonth = `${year}-${month < 10 ? '0' : ''}${month}`;
    let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/amazon/monthlyproductdetails`,
      {
        userId: params.userid,
        month: yearMonth,
      })

    if (response.data) {
      if (response.data.message !== 'No user Found') setMonthlyAmazonGraphData(response.data)

    }
  }
  const fetchWeeklyFlipkartGraphData = async () => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');

    const currentDay = `${year}-${month}-${day}`;
    let weeklyDay = today.getDay() + 1
    let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/flipkart/weeklyproductdetails`,
      {
        userId: params.userid,
        currentDate: currentDay,
        days: weeklyDay
      })

    if (response.data) {
      if (response.data.message !== 'No user Found') setWeeklyFlipkartGraphData(response.data)
    }

  }
  const fetchWeeklyAmazonGraphData = async () => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');

    const currentDay = `${year}-${month}-${day}`;
    let weeklyDay = today.getDay() + 1
    let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/amazon/weeklyproductdetails`,
      {
        userId: params.userid,
        currentDate: currentDay,
        days: weeklyDay
      })

    if (response.data) {
      if (response.data.message !== 'No user Found') setWeeklyAmazonGraphData(response.data)
    }

  }
  useEffect(() => {
    fetchYearlyFlipkartGraphData()
    fetchYearlyAmazonGraphData()
    fetchMonthlyFlipkartGraphData()
    fetchMonthlyAmazonGraphData()
    fetchWeeklyFlipkartGraphData()
    fetchWeeklyAmazonGraphData()
  }, [])
  return (
    <ChartSectionDiv>
      <ChartHeadingDiv>
        <MutableSection>
          {swiggyActive ? <PlatormName>AMAZON SPEND</PlatormName> : <PlatormName>FLIPKART SPEND</PlatormName>}
          <DateSection>
            <DateTopSection  onClick={() => { setToggle((prev) => !prev) }}>
            <DateText>THIS {graphState}</DateText>
            <DateChangeLogoHolder>
              {toggle ?  <DateChangeLogo src={vector} style={{transform:'rotate(180deg)'}} /> : <DateChangeLogo src={vector}  />}
            </DateChangeLogoHolder>
            </DateTopSection>
            
            {toggle && (
              <DataChangeDiv>
                <Option onClick={() => {
                  setGraphState('YEAR')
                  setToggle(false)
                }}>THIS YEAR</Option>
                <Option onClick={() => {
                  setGraphState('MONTH')
                  setToggle(false)

                }
                }>THIS MONTH</Option>
                <Option onClick={() => {
                  setGraphState('WEEK')
                  setToggle(false)
                }
                }>THIS WEEK</Option>
              </DataChangeDiv>
            )}

          </DateSection>
        </MutableSection>
        <RightMutableSection></RightMutableSection>
      </ChartHeadingDiv>
      <Chart />
    </ChartSectionDiv>
  )
}

export default ChartSection
