import React from 'react'
import { LowestSection } from './LowestBottomSection.styled'
import GroupContainer from '../GroupContainer/GroupContainer'
import BiggestOrderContainer from '../BiggestOrderContainer/BiggestOrderContainer'

function LowestBottomSection() {
  return (
    <LowestSection>
      <GroupContainer />
      <BiggestOrderContainer/>
    </LowestSection>
  )
}

export default LowestBottomSection
