import styled from "styled-components";

import gridBase from '../../pictures/Grid Base.png'

export const GroupSection = styled.div`
    height: 285px;
    width: 60%;
    background-image:url(${gridBase});
    background-size:cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left:5px solid #F26724;
    border-bottom:5px solid #F26724;
`;

export const FriendCompareDiv = styled.div`
  height: 100%;
  width: 33%;
`;
export const FriendCompareTitle = styled.h4`
    /* width: 100%; */
    font-weight: 600;
    font-size: 16px;
    font-family: Poppins;
    line-height: 0px;
    text-align: center;
    /* font-size: 20px; */
`
export const FriendHolder=styled.div`
  margin:0px 5px;
  position: relative;
`;
export const FriendCircleDiv = styled.div`
    height: 100%;
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ShareBtn=styled.div`
 height: 10%;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
`;

export const ShareImg=styled.img`
  height: 100%;
  width: 60%;
`;

export const FriendCountDiv=styled.div`
  width: 80%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const FriendCount=styled.div`
 width: 50%;
  /* background: red; */
  display: flex;
  justify-content: flex-end;
`;
export const FriendCountImg=styled.img`
 width: 25.929px;
 height: 25.929px;
`;
export const TotalFriends=styled.div`
 width: 47px;
height: 26px;
flex-shrink: 0;
border-radius: 20px;
border: 0.74px solid #585763;
background: #F1F0F0;
margin-left: -7px;
color: #000;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
`;
