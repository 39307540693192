

import React from 'react'
import { FriendCircleDiv, FriendCompareDiv, FriendCompareTitle, FriendCountDiv, FriendHolder, GroupSection, ShareBtn, ShareImg,FriendCount,FriendCountImg,TotalFriends } from './GroupContainer.styled'
import IndivisualFriend from './IndivisualFriend/IndivisualFriend'
import Share from '../../pictures/Share.png'
import FriendCircle from './IndivisualFriend/FriendCircle'
import model2Icon from "../../pictures/model2.png";
import model3Icon from "../../pictures/model3.png";
import useFooddyStore from '../../store'

const GroupContainer = () => {
    const {friends}=useFooddyStore()
    return (
        <GroupSection>
            <FriendCompareDiv>
                <FriendCompareTitle>Shopping Spree</FriendCompareTitle>
                <FriendHolder>
                    <IndivisualFriend />
                </FriendHolder>
            </FriendCompareDiv>
            <FriendCircleDiv>
                <FriendCircle />
                <FriendCountDiv>
                    <FriendCount>
                        <FriendCountImg src={model2Icon} />
                        <FriendCountImg src={model3Icon} />
                        <TotalFriends>{friends.length}</TotalFriends>
                    </FriendCount>
                </FriendCountDiv>
            </FriendCircleDiv>
        </GroupSection>
    )
}

export default GroupContainer
