import React, { useEffect } from 'react'
import { Circle1, Circle2, Circle3, Container1, Holder1, InsideShoppingLine, MiddleLineDiv, Name1, Profileimg, ShoppingLine, ShoppingLineImage, Spend1, Title1 } from './IndivisualFriend.styled'
import facebook from '../../../pictures/facebook 1.png'
import shoppingLine from "../../../pictures/3d.svg"
import useFooddyStore from '../../../store'

function IndivisualFriend() {
    const {
        swiggyActive,
        userPrflPic,
        userName,
        totalAmount,
        activeFriend
    } = useFooddyStore()
    return (
        <Container1>
            <Holder1>
                <Circle1>
                    <Circle2>
                        <Circle3><Profileimg src={activeFriend.friendDp} /></Circle3>
                    </Circle2>
                </Circle1>
                <Title1>
                    {activeFriend.user_name ? <Name1>{activeFriend.user_name}</Name1> :<Name1>----</Name1>}
                    {!swiggyActive ? (
                        <>
                            {
                                activeFriend.flipkart_total_spent ? (<Spend1>₹{activeFriend.flipkart_total_spent}</Spend1>) : (<Spend1>----</Spend1>)
                            }
                        </>)
                        :
                        (
                            <>
                                {
                                    activeFriend.amazon_total_spent ? (<Spend1>₹{activeFriend.amazon_total_spent}</Spend1>) : (<Spend1>----</Spend1>)
                                }
                            </>
                        )
                    }

                </Title1>
            </Holder1>
            <MiddleLineDiv>
                <ShoppingLine>
                <ShoppingLineImage src={shoppingLine} />
                    <InsideShoppingLine></InsideShoppingLine>
                </ShoppingLine>
            </MiddleLineDiv>
            <Holder1>
                <Circle1>
                    <Circle2>
                        <Circle3><Profileimg src={userPrflPic} /></Circle3>
                    </Circle2>
                </Circle1>
                <Title1>
                    <Name1>{userName}</Name1>
                    {totalAmount ? <Spend1>{totalAmount}</Spend1> : <Spend1>----</Spend1>}  
                </Title1>
            </Holder1>
        </Container1>

    )
}

export default IndivisualFriend
