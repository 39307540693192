import styled from "styled-components";


export const ChallengeBox = styled.div`
width: 28%;
height: 100%;
/* background-color: gray; */
.challengeHeader{
    color: #3C3C3C;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
    margin-bottom: 20px;

}
`

export const ChallengeBoxDetails = styled.div`
width: 100%;
height: 85%;
flex-shrink: 0;
/* opacity: 0.6000000238418579; */
background: url(${proops => proops.fruitbackground}), #f6f1ff 0px -40.473px / 100% 122.429% no-repeat;
background-size: cover;
background-repeat: no-repeat;
box-sizing: border-box;
/* border: 1px solid red; */
.PositionWrapper{
    width: 100%;
    /* height: 100px; */
    /* background: green; */
    
    /* display: flex; */
    padding: 20px 22px 17px 30px;
    box-sizing: border-box;

}
.positionBox{
    width: 80%;
    margin-bottom: 20px;
    /* background: blue; */
}
.userNm{
    color: #3C3C3C;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.awardWrapper{
    color: #F26724;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.56px;
display: flex;
align-items: center;
margin-top: 6px;
}
.awardWrapper img{
    margin-right: 10px;
    
}
.userRankDiv{
    width: 50%;
    /* background: red; */
    display: flex;
    justify-content: flex-end;
}
.userRankDiv img{
    width: 25.929px;
height: 25.929px;
}
.totalUser{
    width: 47px;
height: 26px;
flex-shrink: 0;
border-radius: 20px;
border: 0.74px solid #585763;
background: #F1F0F0;
margin-left: -7px;
color: #000;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
}
.monthWrapper{
    color: #969696;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -1.26px;
padding: 20px 22px 17px 30px;
/* background: red; */
display: flex;
    justify-content: space-between;
}
.monthlyAmt{
    color: #414141;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.shareDiv{
    display: flex;
width: 40px;
height: 40px;
padding: 8px 7px 7px 8px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 400px;
border: 1px solid #000;
box-sizing: border-box;
cursor: pointer;
}
.shareDiv:hover{
    background: #F26724;
    border: none
}

`