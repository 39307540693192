import {
  BigOrderContainer,
  FoodImg,
  NoUserCont,
} from "./BiggestorderContainer.styled";
import foodModel from "../../pictures/foodModel.png";
import useFooddyStore from "../../store";
import BigOrderLoader from "../SkeletonLoader/BigOrderLoader";
import BiggestOrderContImg from "../../pictures/noDataFoundBg.png";

const BiggestOrderContainer = () => {
  const { dishesList, noUser, amountLoader } = useFooddyStore();

  // console.log('nouser', noUser, dishesList)
  return (
    <BigOrderContainer>
      {
        // !noUser &&
        <>
          <div className="bigOrderHeader">Your Top Expensive Order</div>

          {amountLoader ? (
            <BigOrderLoader />
          ) : noUser ? (
            <NoUserCont bg={BiggestOrderContImg}>no data found</NoUserCont>
          ) : (
            dishesList.map((e, index) => (
              <div key={index} className="bigOrderWrapper">
                <FoodImg src={e.productImageUrl} />
                <div className="bigOrderDetail">
                  <div>{`${e.productName.slice(0, 30)}...`}</div>
                  <div className="orderAmt">{`₹${e.productPrice}`}</div>
                </div>
              </div>
            ))
          )}
        </>
      }
    </BigOrderContainer>
  );
};
export default BiggestOrderContainer;
