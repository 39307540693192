import styled from "styled-components";


export const FriendCircleContainer = styled.div`
     height: 250px;
     width: 250px;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
`;

export const FriendCircleBox1 = styled.div`
    height: 220px;
    width: 220px;
    border-radius: 220px;
    border: 2px dashed #F26724;
   display: flex;
   justify-content: center;
   align-items: center;
`;


export const FriendCircleBox2 = styled.div`
background-color: #FFC6DB;
    height: 180px;
    width: 180px;
    border-radius: 180px;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
`;

export const FriendCircleBox3 = styled.div`
    height: 120px;
    width: 120px;
    border-radius: 120px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #FFF1D2;
`;
