import {
  SharePageBanner,
  RandomImgDiv,
  SharepageCont,
  SharePageDetailDiv,
  ProfilePic,
  GreetMessage,
  UserShareCard,
  SharePageDec,
  SharContainer,
  ShareIconDiv,
  PublicShareContainer,
  PublicShareContImg,
} from "./UserSharePage.styled";
import { Line } from "../SkeletonLoader/SkeletonLoader.styled";
import { Button } from "../Body/Share.styled";
import changIcon from "../..//pictures/change.svg";

import leaderModel from "../../pictures/leaderModel.png";
import StarIcon from "../../pictures/star.png";

import LinkIcon from "../../assets/shareIcons/link.svg";
import whatAppIcon from "../../assets/shareIcons/WhatsApp.svg";
import Twittericon from "../../assets/shareIcons/Twitter.png";
import FBIcon from "../../assets/shareIcons/Facebook.png";
import InstaIcon from "../../assets/shareIcons/Instagram.png";
import SnapIcon from "../../assets/shareIcons/Snapchat.svg";
import foodTray from "../../pictures/foodTray.svg";
import bannerImg from "../../pictures/sharePageBannerImg.png";

import man1Icon from "../../assets/menEmoji/man1.png";
import man2Icon from "../../assets/menEmoji/man2.png";
import man3Icon from "../../assets/menEmoji/man3.png";
import man4Icon from "../../assets/menEmoji/man4.png";
import man5Icon from "../../assets/menEmoji/man5.png";
import man6Icon from "../../assets/menEmoji/man6.png";
import man7Icon from "../../assets/menEmoji/man7.png";
import man8Icon from "../../assets/menEmoji/man8.png";

import woman1Icon from "../../assets/womenEmoji/woman1.png";
import woman2Icon from "../../assets/womenEmoji/woman2.png";
import woman3Icon from "../../assets/womenEmoji/woman3.png";
import woman4Icon from "../../assets/womenEmoji/woman4.png";
import woman5Icon from "../../assets/womenEmoji/woman5.png";
import woman6Icon from "../../assets/womenEmoji/woman6.png";
import woman7Icon from "../../assets/womenEmoji/woman7.png";
import woman8Icon from "../../assets/womenEmoji/woman8.png";
import woman9Icon from "../../assets/womenEmoji/woman9.png";
import woman10Icon from "../../assets/womenEmoji/woman10.png";
import woman11Icon from "../../assets/womenEmoji/woman11.png";
import woman12Icon from "../../assets/womenEmoji/woman12.png";
import { useState } from "react";

import useFooddyStore from "../../store";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useEffect } from "react";

const UserSharePage = (props) => {
  const [isMan, setIsMan] = useState(false);
  // const [userPrflPic, setUserPrflPic] = useState()

  const {
    setFooddyId,
    fooddyId,
    totalAmount,
    swiggyActive,
    userName,
    gender,
    userPrflPic,
    setUserPrflPic,
    userId,
    rank,
    profileLoader,
  } = useFooddyStore();

  function getRandomImg() {
    const imgArr = [
      man1Icon,
      man2Icon,
      man3Icon,
      man4Icon,
      man5Icon,
      man6Icon,
      man7Icon,
      man8Icon,
      woman1Icon,
      woman2Icon,
      woman3Icon,
      woman4Icon,
      woman5Icon,
      woman6Icon,
      woman7Icon,
      woman8Icon,
      woman9Icon,
      woman10Icon,
      woman11Icon,
      woman12Icon,
    ];
    const randomImg = imgArr[Math.floor(Math.random() * imgArr.length)];
    return randomImg;
  }

  function getRandomPercentage(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min + "%";
  }

  const getRandomColor = () => {
    const colors = ["#BEF0C6", "#FFC7D6", "#DBD1FC", "#FFD9A2"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const getRandomMan = () => {
    const imgArr = [
      man1Icon,
      man2Icon,
      man3Icon,
      man4Icon,
      man5Icon,
      man6Icon,
      man7Icon,
      man8Icon,
    ];
    const randomManImg = imgArr[Math.floor(Math.random() * imgArr.length)];
    return randomManImg;
  };

  const getRandomWoman = () => {
    const imgArr = [
      woman1Icon,
      woman2Icon,
      woman3Icon,
      woman4Icon,
      woman5Icon,
      woman6Icon,
      woman7Icon,
      woman8Icon,
      woman9Icon,
      woman10Icon,
      woman11Icon,
      woman12Icon,
    ];
    const randomWomanImg = imgArr[Math.floor(Math.random() * imgArr.length)];
    return randomWomanImg;
  };

  useEffect(() => {
    if (gender === "male") {
      setUserPrflPic(getRandomMan());
    } else {
      setUserPrflPic(getRandomWoman());
    }
  }, []);

  const changePicFn = () => {
    if (gender === "male") {
      setUserPrflPic(getRandomMan());
    } else {
      setUserPrflPic(getRandomWoman());
    }
  };
  const currentUrl = `http://localhost:3000/share/${swiggyActive ? "amazon" : "flipkart"
    }/${userId}`;

  const copyShare = () => {
    navigator.clipboard.writeText(
      `http://localhost:3000/share/${swiggyActive ? "amazon" : "flipkart"
      }/${userId}`
    );
  };

  return (
    <SharepageCont>
      <SharePageBanner className="sharepageBanner" bannerBg={bannerImg}>
        <ProfilePic prflPic={userPrflPic} bgColor={getRandomColor}>
          <div className="SharePageDetailInnerDiv">
            <div className="changePic" onClick={changePicFn}>
              <img src={changIcon} />
            </div>
          </div>
        </ProfilePic>
      </SharePageBanner>

      <SharePageDetailDiv>
        <GreetMessage>
          Hey Buddy, I’m {props.usNm ? props.usNm : userName} here!
        </GreetMessage>
        {profileLoader ? (
          <Line height="21" width="129" margin="10px" />
        ) : (
          <UserShareCard modelImg={StarIcon}>
            <div className="wrap">
              <div className="sharePageSl">{rank}</div>
              <div className="sharePageImg">
                <img src={userPrflPic} />
              </div>
              <div className="sharePageName">
                {props.usNm ? props.usNm : fooddyId}
              </div>
            </div>
            <div className="sharePageAmt">
              ₹ {props.tA ? props.tA : totalAmount}
            </div>
          </UserShareCard>
        )}

        <SharePageDec>
          Hey buddy, I spent ₹ {props.tA ? props.tA : totalAmount} on{" "}
          {swiggyActive ? "amazon" : "flipkart"} . How about you? Let's compare!
        </SharePageDec>

        {props.publicShare ? (
          <PublicShareContainer
            href="https://chrome.google.com/webstore/detail/spending-tracker-for-amaz/pmkoilojjepdgnbhnbfmbpjbcgfgbaoe"
            target="_blank"
          >
            <PublicShareContImg src={foodTray} />
            Calculate my {props.swiggyActive ? "amazon" : "flipkart"} spending
          </PublicShareContainer>
        ) : (
          <SharContainer>
            <div className="txt">Share</div>
            <div className="shareButtonsDiv">
              <ShareIconDiv ShareIcon={LinkIcon} onClick={copyShare} />
              <ShareIconDiv>
                <FacebookShareButton
                  url={currentUrl}
                  quote={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                    } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! `}
                  hashtag="#Ecom"
                >
                  <FacebookIcon size={41} round />
                </FacebookShareButton>
              </ShareIconDiv>
              <ShareIconDiv>
                <TwitterShareButton
                  url={currentUrl}
                  title={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                    } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! 
My Ecom report: `}
                  hashtags={[
                    "ecom",
                    "amazon",
                    "flipkart",
                    "spending_calculator",
                  ]}
                >
                  <TwitterIcon size={41} round />
                </TwitterShareButton>
              </ShareIconDiv>
              <ShareIconDiv>
                <WhatsappShareButton
                  url={currentUrl}
                  title={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                    } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! `}
                // My Ecom report: https://app.ecombullet.com/share/${!swiggyActive ? "zomato" : "swiggy"}/${userId}`}
                >
                  <WhatsappIcon size={41} round />
                </WhatsappShareButton>
              </ShareIconDiv>

              <ShareIconDiv>
                <LinkedinShareButton
                  url={currentUrl}
                  title={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                    } online ecom expenses`}
                  summary={`A whopping ${totalAmount}! 🍽️💸 Who knew deliciousness could cost so much? 😅 Have you checked yours? Let's compare! `}
                  source={`https://app.ecombullet.com/share/${!swiggyActive ? "flipkart" : "amazon"
                    }/${userId}`}
                >
                  <LinkedinIcon size={41} round />
                </LinkedinShareButton>
              </ShareIconDiv>
            </div>
          </SharContainer>
        )}
      </SharePageDetailDiv>
    </SharepageCont>
  );
};

export default UserSharePage;
