import logo from "../../assets/fooddy-logo.png";
import {
  HeaderContainer,
  Container,
  IdAndShare,
  GetExtension,
} from "./Header.styled";

import id from "../../assets/id.png";
import useFooddyStore from "../../store";

const Header = () => {
  const extensionURL = () => {
    window.location.href =
      "https://chrome.google.com/webstore/detail/spending-tracker-for-amaz/pmkoilojjepdgnbhnbfmbpjbcgfgbaoe";
  };

  const { fooddyId, setShareClicked, isShareClicked, totalAmount } =
    useFooddyStore();
  return (
    <HeaderContainer>
      <img src={logo} alt="logo" />
      <IdAndShare>
        <Container id="fooddy-id-container">
          <div id="idImage">
            <img id="id-img" src={id} alt="id" />
          </div>
          <div>
            <div id="fooddy-id">{fooddyId}</div>
          </div>
          <GetExtension onClick={extensionURL}>Get extension</GetExtension>
        </Container>
      </IdAndShare>
    </HeaderContainer>
  );
};

export default Header;
