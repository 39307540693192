import styled from "styled-components";


export const LowestSection=styled.div`
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`;