import styled from "styled-components";


export const BigOrderContainer = styled.div`
    height: 275px;
    margin-left: 10px;
    background: white;
    padding: 10px;
    border-radius: 20px;
    width: 45%;

/* background-color: green; */
.bigOrderHeader{
    color: #3C3C3C;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
    margin-bottom: 28px;
 text-align: center;
}
.bigOrderWrapper{
    display: flex;
    align-items: center;
    color: #3C3C3C;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 20px;

}
.bigOrderDetail{
    margin-left: 20px;
}
.orderAmt{
    color: #6B6B6B;

}
`

export const FoodImg = styled.img`
width: 46px;
height: 46px;
flex-shrink: 0;
border-radius: 4px;

`

export const NoUserCont = styled.div`
width: 100%;
height: 220px;
/* background-color: red; */
background: url(${props => props.bg}),  #f6f1ff 0px -40.473px / 100% 122.429% no-repeat;
color: #606060;
text-align: right;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
`