import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import useFooddyStore from '../../store';
import './chart.css'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);


const Chart = () => {
  const [graphMappingData, setGraphMappingData] = useState(null)
  const {
    userId,
    swiggyActive,
    yearlyFlipkartGraphData,
    monthlyFlipkartGraphData,
    weeklyFlipkartGraphData,
    yearlyAmazonGraphData,
    monthlyAmazonGraphData,
    weeklyAmazonGraphData,
    graphState
  } = useFooddyStore()


  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
  };
  const ProcessData = (activeState, flipkartData, amazonData, labels) => {
    const data = {
      labels,
      datasets: [
        {
          type: 'line',
          label: 'Line Dataset',
          data: flipkartData,
          fill: true,
          borderColor: activeState ? 'black' : '#F26724',
          tension: 0.5,
        },
        {
          type: 'line',
          label: 'Line Dataset',
          data: amazonData,
          fill: false,
          borderColor: activeState ? '#F26724' : 'black',
          tension: 0.5,
        }
      ],
    };
    setGraphMappingData(data)
  }
  useEffect(() => {
    if (graphState === 'YEAR') {
      const currentMonth = new Date().getMonth() + 1;
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const yearLable = months.slice(0, currentMonth);
      ProcessData(swiggyActive, yearlyFlipkartGraphData, yearlyAmazonGraphData, yearLable)
    }
    if(graphState === 'MONTH'){
      const currentDay = new Date().getDate();
      const date=[]
      let i=0
      while(i<31){
        i++
        date.push(i)
      }
      const monthLable=date.slice(0,currentDay)
      ProcessData(swiggyActive, monthlyFlipkartGraphData,monthlyAmazonGraphData , monthLable)
    }
    if(graphState === 'WEEK'){
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let currentDate=new Date().getDay()
      let weekLable=daysOfWeek.splice(0,currentDate+1)
      ProcessData(swiggyActive, weeklyFlipkartGraphData,weeklyAmazonGraphData, weekLable)
    }
  }, [userId,
    swiggyActive,
    yearlyFlipkartGraphData,
    monthlyFlipkartGraphData,
    weeklyFlipkartGraphData,
    yearlyAmazonGraphData,
    monthlyAmazonGraphData,
    weeklyAmazonGraphData,
    graphState])
  return (
    <div style={{ width: '100%', height: '80%' }}>
      {
        graphMappingData && (
          <Line data={graphMappingData} options={options} />
        )
      }
    </div>
  );
};


export default Chart
