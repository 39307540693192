import styled from "styled-components";


export const ProfileMainContainer = styled.div`
cursor: pointer;
  position: absolute;
  height: 20px;
  width: 20px;
  z-index: 999;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f7e4c4;  Light yellow background */
  height:100%; /* Adjust height as needed */
  width: 100%;
  position: relative;
`;

export const ProfileCircle = styled.div`
  width: 100%; /* Adjust size as needed */
  height: 100%;
  position: relative;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 999;
`;

export const ArcContainer = styled.div`
  position: absolute;
  width: 110%; /* Adjust size as needed */
  height: 110%;
  transform: rotate(90deg);
`;

export const Arc = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
`;