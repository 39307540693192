import styled from "styled-components";

export const MiddleContainerWrapper = styled.div`
width: 100%;
height: 100%;
background-color: #F9F6FF;
padding: 0px 35px ;

`
export const MiddleContainerHeader = styled.div`
width: 100%;
/* height: 20px; */
/* background-color: red; */
color: #000;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
/* margin-top: 51px; */
margin: 51px 0px 0px 0px;
`

export const MiddleContainerDesc = styled.div`
width: 100%;
height: 66px;
/* background-color: green; */
margin-top: 28px;
color: #000;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
span{
    color: #F26724;

}
`

export const BrandcardContainer = styled.div`
width: 100%;
height: 200px;
/* background-color: yellow; */
margin-top: 0px;
display: flex;
align-items: center;
justify-content: space-between;
`

export const LowerCardsContainer= styled.div`
width: 100%;
height: 30%;
/* height: 200px; */
/* background-color: red; */
margin-top: 10px;
display: flex;
justify-content: space-between;
/* align-items: center; */
`