import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  MiddleContainerWrapper,
  MiddleContainerHeader,
  MiddleContainerDesc,
  BrandcardContainer,
  LowerCardsContainer,
} from "./MiddleContainer.styled";
import BrandCard from "../BrandCard/Brandcard";
import BrandCardLoader from "../SkeletonLoader/BrandCardLoader";
import BiggestOrderContainer from "../BiggestOrderContainer/BiggestOrderContainer";
import ChallengeContainer from "../ChallengeContainer/ChallengeContainer";
import amazonLight from "../../pictures/amazonLight.svg";
import amazonDark from "../../pictures/amazonDark.svg";
import flipkartLight from "../..//pictures/flipkartLight.svg";
import flipkartDark from "../../pictures/flipkartDark.svg";
import { useState } from "react";
import useFooddyStore from "../../store";
import LowestBottomSection from "../LowestBottomSection/LowestBottomSection";
import ChartSection from "../ChartSection/ChartSection";
const MiddleContainer = () => {
  const params = useParams();

  const {
    setFooddyId,
    setSwiggyActivated,
    isShareClicked,
    setShareClicked,
    fooddyId,
    setDishesList,
    isZomatoActivated,
    setTotalAmount,
    totalAmount,
    userId,
    setUserId,
    swiggyActive,
    setSwiggyActive,
    noUser,
    setUserName,
    setMonthlyAmt,
    setRank,
    setGender,
    setUserPrflPic,
    userPrflPic,
    setLeaderId,
    setNoUser,
    setUserFetch,
    userFetched,
    setAmountLoader,
    amountLoader,
    setFriends
  } = useFooddyStore();

  const fetchLocalData = async () => {
    try {
      setAmountLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/${swiggyActive ? "amazon" : "flipkart"
        }/product`,
        {
          userId: params.userid,
          // userId: "02ac1ed1-5dce-11ee-b9da-7f4be48d3577",
        }
      );
      setAmountLoader(false);
      if (response?.data?.totalAmountSpent) {
        setTotalAmount(response.data.totalAmountSpent.toString());
        setDishesList(response.data.topOrderedProducts);
        setNoUser(false);
      } else {
        setTotalAmount(response.data?.error);
        setNoUser(true);
        setDishesList([]);
      }
    } catch (error) { }
  };
  const fetchFriendsData = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/invitation/invitors`,
      {
        mapUserId: params.userid,
      })
      if(response.data){
        setFriends(response.data)
      }
  }
  useEffect(() => {
    if (userId) {
      fetchLocalData();
      fetchFriendsData()
    }
  }, [userId, swiggyActive]);

  const getActiveStats = (e) => {
    if (e != swiggyActive) {
      setSwiggyActive(e);
    }
  };

  return (
    <MiddleContainerWrapper>
      <MiddleContainerHeader>Ecom Spending Calculator</MiddleContainerHeader>
      <MiddleContainerDesc>
        <span>Shopping </span>wisely is an investment in happiness
      </MiddleContainerDesc>
      {amountLoader ? (
        <BrandcardContainer>
          <BrandCardLoader />
          <BrandCardLoader />
        </BrandcardContainer>
      ) : (
        <BrandcardContainer>
          <BrandCard
            brandLogo={swiggyActive ? amazonLight : amazonDark}
            swiggyState={swiggyActive}
            onData={getActiveStats}
            brandName="amazon"
          />
          <BrandCard
            brandLogo={swiggyActive ? flipkartDark : flipkartLight}
            swiggyState={swiggyActive}
            onData={getActiveStats}
            brandName="flipkart"
          />
        </BrandcardContainer>
      )}

      <LowerCardsContainer>
        {/* <BiggestOrderContainer /> */}
          <ChartSection/>
        {<ChallengeContainer />}
      </LowerCardsContainer>
      <LowestBottomSection />
    </MiddleContainerWrapper>
  );
};

export default MiddleContainer;
