import { Arc, ArcContainer, Container, ProfileCircle, ProfileImage, ProfileMainContainer } from "./ProfileComponent.styled";

import test from '../../../pictures/foodTray.svg'
import { Heading } from "../../InviteContainer/InviteContainer.styled";
import useFooddyStore from "../../../store";
import { useEffect } from "react";



const ProfileComponent = ({ style, friendDp, friend }) => {
    const {setActiveFriend}=useFooddyStore()
    const handleClick=()=>{
        friend['friendDp']=friendDp
        setActiveFriend(friend)
    }
    return (
        <ProfileMainContainer style={style} onClick={handleClick}>
            <Container>
                <ProfileCircle>
                    <ProfileImage src={friendDp} alt="Profile" />
                    <ArcContainer>
                        <Arc viewBox="0 0 36 36">
                            <path
                                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                stroke="#ef7b45"
                                strokeWidth="4"
                                strokeDasharray="75, 100"
                            />
                        </Arc>
                    </ArcContainer>
                </ProfileCircle>
            </Container>
        </ProfileMainContainer>


    );
}

export default ProfileComponent;
