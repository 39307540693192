import styled from "styled-components";

export const ReferContainer = styled.div`
   height: 385px;
   width: 90%;
   position: absolute;
   bottom: 15px;
   background-color: #FFFF;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const TopDiv = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid lightgray;
`;

export const TitleBox = styled.span`
    cursor: pointer;
  width: 60px;
  margin:0px 20px;
  text-align: center;
  border-bottom: 5px solid ${(props) => (props.active ? '#D7702E' : 'none')};
 color:${(props) => (props.active ? '#D7702E' : 'black')} ;
`;

export const Heading = styled.div`
       font-family: Poppins;
      height: auto;
      margin:7px 50px 0px 50px;
       font-size: 20px;
       line-height: 25px;
       text-align: center;
`;

export const InviteImg = styled.img`
    height: 160px;
    width: 160px;
`;

export const InviteCodeDiv = styled.div`
    height: 42px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    box-shadow: 0px 0px 5px #FFF1D2;
    border-radius: 10px;
`;
export const CodeSpace = styled.div`
font-family: Popins;
font-weight: 500;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CodeCopyBtn = styled.div`
font-family: Poppins;
font-size: 10px;
font-weight: 500;
  margin:0px 1%;
  width: 30%;
 cursor: pointer;
 background-color: #F26724;
 color: white;
 text-align: center;
 padding: 8px 10px;
 border-radius: 5px;
`;


export const ShareDiv = styled.div`
  height: 30px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items:center;
`;

export const IconsDiv = styled.div`
  height: 25px;
  margin:0px 8px;
`;

export const Icons = styled.img`
  height: 100%;
  width: 100%;
`;

export const InvitationList = styled.div`
    height: 70%;
    width: 90%;
    padding:0px 2px;
    overflow-y: scroll;
  &::-webkit-scrollbar{
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #F26724; /* Color of the scrollbar handle */
    border-radius: 10px; /* Roundness of the handle */
    border: 3px solid transparent; /* Space around the handle */
}
  &::-webkit-scrollbar-track {
    border-radius: 10px; 
}
`;
export const IndivisualInvitorsList = styled.div`
    height: 50px;
    width: 100%;
    margin-top: 7px;
    display: flex;
    flex-wrap:wrap;
    justify-content: start;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 5px lightgray;
`;

export const ProfilePic = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 40px;
`;

export const UserNameDiv = styled.div`
  height: 100%;
  width: calc(100%-40px);
  text-align: start;
  display: flex;
  align-items: center;
`;

export const InvitationHolder = styled.div`
  height: auto;
  width: 90%;
  position: absolute;
  bottom: 10px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: red;
  border-radius:10px;
`;

export const InvitationStatusDiv = styled.div`
font-family: Poppins;
font-weight: 500;
font-size: 14px;
   height: auto;
   width: 90%;
   background-color: red;
`;

export const EnterCodeDiv = styled.div`
  height: 40px;
  width: 100%;
  background-color: white;
  margin:0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #F26724;
`;

export const FormElem = styled.form`
height: 100%;
width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CodeInput = styled.input`
    height: 70%;
    width: 70%;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
   &:focus{
     border: none;
     outline: none;
   }
`;

export const CodeSubmit = styled.button`  
font-family: Poppins;
font-size: 10px;
font-weight: 500;
color: white;
border: none;
  height: 80%;
  width: 25%;
  background-color: #F26724;
  text-align: center;
  border-radius: 5px;
    margin: 0 2.5%;
    cursor: pointer;
        box-shadow: 0px 0px 5px rgb(242, 103, 36);
`;
