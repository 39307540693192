import styled from "styled-components";


export const ChartSectionDiv = styled.div`
   height: 100%;
   width: 70%;
   background-color: white;
`;

export const ChartHeadingDiv = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MutableSection = styled.div`
   width: 55%;
   height: 100%;
     display: flex;
  justify-content: center;
  align-items: center;
`;
export const RightMutableSection = styled.div`
width: 45%;
height: 100%;
  display: flex;
justify-content: center;
align-items: center;
`;

export const PlatormName = styled.div`

font-family:Poppins;
font-weight: 600;
   width: 60%;
   border-right:2px solid gray;
   font-weight: bolder;
   text-align: center;
`;
export const DateSection = styled.div`
 width: 40%;
 position: relative;
`;
export const DateTopSection = styled.div`
height: 100%;
width: 100%;
cursor: pointer;
display: flex;
 align-items: center;
 justify-content: center;
`;
export const DateText = styled.span`
font-family: Poppins;
font-weight: 400;
font-size: 14px;
  width: 80%;
  text-align: end;
`;
export const DateChangeLogoHolder = styled.span`
    width: 20%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 `;
export const DateChangeLogo = styled.img`
  width: auto;
`;

export const DataChangeDiv = styled.div`
  height: 60px;
  width: 100%;
  background-color: red;
  position: absolute;
  z-index: 5;
  top:100%;
`;
export const Option = styled.option`
font-family: Poppins;
font-weight: 400;
font-size: 14px;
  height: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  border-bottom: 2px solid white;
`;