import styled from "styled-components";
import { devices } from "../../constants/devices";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 3px 6px #00000029;

  img {
    width: 120px;
    height: 29px;
  }
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  #browser-img {
    width: 25px;
    height: 25px;
    margin-left: 13px;
  }
  #id-img {
    width: 17px;
    height: 16px;
    margin-left: 12px;
  }
  .copy-img {
    width: 18px;
    height: 17px;
    cursor: pointer;
  }
  div {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #3d3d3d;
  }
  #url {
    font-family: poppins-normal-italic;
    margin-left: 16px;
    width: 160px;
    margin-right: 46px;

    @media ${devices.tablet} {
      width: 311px;
      margin-right: 54px;
    }
  }
  #Ecom-id {
    font-family: poppins-normal;
    width: 85px;
    margin-left: 6px;
  }
  #idImage {
    display: flex;
  }
`;
export const IdAndShare = styled.div`
  display: none;
  @media ${devices.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: relative;
  }
`;
export const GetExtension = styled.div`
  font-family: poppins-normal;
  letter-spacing: 0px;
  color: #6a36f0 !important;
  width: 142px;
  height: 31px;
  border: 1px solid #6a36f0;
  border-radius: 21px;
  opacity: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
