import {
  BrandCardContainer,
  BrandLogoWrapper,
  BrandDescText,
  ExpenseText,
  ExpenseAmt,
  InfoSecDiv,
} from "./Brandcard.styled";
import useFooddyStore from "../../store";
import InfoIcon from "../../pictures/infoIcon.png";

import { useEffect, useState } from "react";
const BrandCard = (props) => {
  const { dishesList, totalAmount, noUser, setTotalAmount } = useFooddyStore();
  const [showInfoDiv, setShowInfoDiv] = useState(false);

  useEffect(() => {
    setShowInfoDiv(false);
  }, []);

  useEffect(() => {}, [props.swiggyState]);

  const propsCheck = () => {
    // if(props,brandLogo.includes())

    if (props.brandName === "amazon") {
      props.onData(true);
    } else {
      props.onData(false);
    }
  };
  const showInfo = () => {
    setShowInfoDiv(true);
  };
  const hideInfo = () => {
    setShowInfoDiv(false);
  };
  return (
    <BrandCardContainer
      onClick={propsCheck}
      cardState={props}
      infoIcon={InfoIcon}
    >
      <div className="brandLogoDivWrapper">
        <BrandLogoWrapper src={props.brandLogo} />
        {props.brandName === "amazon" && props.swiggyState && noUser ? (
          <div
            className="infoDiv"
            onMouseEnter={showInfo}
            onMouseLeave={hideInfo}
          >
            {showInfoDiv && (
              <InfoSecDiv>
                <div className="infoHeader">How to analyze?</div>
                <ol>
                  <li>Open swiggy/zomato Website</li>
                  <li>Login to your swiggy/zomato account on web</li>
                  <li>
                    Click on the extension icon while on swiggy/zomato website
                    and click calculate now
                  </li>
                </ol>
              </InfoSecDiv>
            )}
          </div>
        ) : props.brandName === "flipkart" && !props.swiggyState && noUser ? (
          <div
            className="infoDiv"
            onMouseEnter={showInfo}
            onMouseLeave={hideInfo}
          >
            {showInfoDiv && (
              <InfoSecDiv>
                <div className="infoHeader">How to analyze?</div>
                <ol>
                  <li>Open swiggy/zomato Website</li>
                  <li>Login to your swiggy/zomato account on web</li>
                  <li>
                    Click on the extension icon while on swiggy/zomato website
                    and click calculate now
                  </li>
                </ol>
              </InfoSecDiv>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <BrandDescText>
        Ecom spending calculator generated a report of your{" "}
        {props.brandName + " "} monthly expense monthly expense
      </BrandDescText>
      <ExpenseText>Total amount spent</ExpenseText>
      <ExpenseAmt
        style={{
          fontSize:
            props.brandName === "amazon" && props.swiggyState && noUser
              ? "14px"
              : props.brandName === "flipkart" && !props.swiggyState && noUser
              ? "14px"
              : "28px",
        }}
      >
        {props.brandName === "amazon" && props.swiggyState
          ? noUser
            ? "not yet analyzed"
            : `₹ ${totalAmount}`
          : props.brandName === "flipkart" && !props.swiggyState
          ? noUser
            ? "not yet analyzed"
            : `₹ ${totalAmount}`
          : "XXXX"}
      </ExpenseAmt>
    </BrandCardContainer>
  );
};
export default BrandCard;
